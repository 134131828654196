<template>
    <div class="d-flex flex-column justify-center align-center fill-height py-8 overflow-hidden">
        <v-card width="350">
            <v-card-title class="justify-center align-center nuvolos-header">
                <v-img max-width="200" max-height="22" class="py-5" src="@/assets/Nuvolos-transparent.svg" />
            </v-card-title>

            <h2 class="secondary--text text-center text-h6 mt-5">Preparing your workspace</h2>

            <v-card-text>
                This might take a few seconds.
                <br />
                Please bear with us until we're done.
            </v-card-text>
            <v-card-text class="text-center pb-16">
                <dashboard-error v-if="error" :msg="errorMsg" />
                <template v-else>
                    <p>{{ signupFlowProgressText }}</p>
                    <v-progress-linear stream buffer-value="0" v-model="progress" color="primary" rounded height="6"></v-progress-linear>
                </template>
                <v-btn v-if="error?.response?.data?.code === 'user_already_exists'" :to="{ name: 'login' }" class="mt-4">go to login</v-btn>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapState } from 'vuex'
const DashboardError = () => import('@/components/DashboardError.vue')

export default {
    name: 'InvitationPassword',
    mixins: [],
    components: { DashboardError },
    data() {
        return {
            signupFlowProgressText: '',
            error: null,
            errorMsg: '',
            progress: 0,
            overviewURI: '',
            dashboardOid: ''
        }
    },
    methods: {
        async flowBeforeAuth() {
            this.signupFlowProgressText = 'Creating user account.'
            const step1 = await this.createUser()
            if (this.$isError(step1)) return
            this.progress = 33

            this.signupFlowProgressText = 'User account created, please sign in.'
            await new Promise(resolve => setTimeout(resolve, 800))

            this.signIn()
        },
        async signUpFlow() {
            this.progress = 66

            this.signupFlowProgressText = 'Finalizing configuration.'
            await this.acceptInvitation()
            this.progress = 100

            this.signupFlowProgressText = 'Thank you for your patience!'
            await new Promise(resolve => setTimeout(resolve, 800))
            this.$router.push({ name: 'home-dashboard', params: { oid: this.dashboardOid }, query: { invitation_target: this.overviewURI } })
        },
        async createUser() {
            try {
                const { data } = await this.$axios.post(`/users/register_with_invitation/auth0/${this.$route.params.invid}`, {
                    email: this.signupUserDetails.email,
                    family_name: this.signupUserDetails.lastName,
                    given_name: this.signupUserDetails.firstName,
                    password: this.signupUserDetails.password
                })
                this.$store.dispatch('getUserInfo')
                return data
            } catch (error) {
                if (error?.response?.data?.code === 'connection_already_registered') {
                    this.errorMsg = 'A Nuvolos account with this email address already exists, please log in instead.'
                }
                if (error?.response?.data?.code === 'password_complexity_error') {
                    this.errorMsg = 'Your password does not satisfy the password complexity constraints. Note that your password cannot contain your name.'
                }
                this.error = error
                return error
            }
        },
        signIn() {
            this.$auth.loginWithRedirect({
                connection: process.env.VUE_APP_AUTH0_CONNECTION,
                login_hint: this.signupUserDetails.email,
                appState: {
                    targetUrl: this.$router.resolve({
                        name: 'invitation-password',
                        params: { invid: this.$route.params.invid }
                    }).href
                }
            })
        },
        async acceptInvitation() {
            try {
                const { data } = await this.$axios.get(`/invitations/accept/${this.$route.params.invid}`)
                this.overviewURI = data.landing_page.split('nuvolos.cloud').pop()
                this.dashboardOid = this.overviewURI.split('/')[2]
                this.$store.dispatch('fetchUserOrgs')
                return data
            } catch (error) {
                this.error = error
                return error
            }
        }
    },
    computed: {
        ...mapState('userStore', ['signupUserDetails'])
    },
    watch: {
        '$auth.isAuthenticated': {
            handler(authenticated) {
                if (authenticated) {
                    this.signUpFlow()
                } else {
                    this.flowBeforeAuth()
                }
            },
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/signup.scss';
</style>
